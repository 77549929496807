import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  ICreateSandboxUserForm,
  IISearchOrganizationsResponse,
  ISearchOrganizations,
} from 'features/CreateSnadboxUser/CreateSandboxUserModal/model/types';
import { IRegistrationForm, ISetPasswordArgs } from 'features/Registration';
import { ISetFactsArgs, IUDataArgs } from 'features/UserCompliant/model/types';
import { IEmailForResetPassForm } from 'pages/ResetPassPage';

export const authApi = createApi({
  reducerPath: 'authApi',
  //   tagTypes: ['Private'],
  baseQuery: fetchBaseQuery({ baseUrl: '/api/v1' }),
  endpoints: (builder) => ({
    logout: builder.mutation({
      query: () => ({
        url: '/logout',
        method: 'GET',
      }),
    }),
    hearbeat: builder.query({
      query: () => ({
        url: '/heartbeat',
        method: 'GET',
      }),
    }),
    config: builder.query({
      query: () => ({
        url: '/config',
        method: 'GET',
        validateStatus: (response) => {
          return response.status === 200 || response.status === 302;
        },
      }),
    }),
    udata: builder.query<void, IUDataArgs>({
      query: (arg) => ({
        url: `/udata?ifId=${arg.ifId}&rights=${0}`,
        method: 'GET',
      }),
    }),
    setFacts: builder.mutation<void, ISetFactsArgs>({
      query: (args) => ({
        url: '/setfact',
        method: 'POST',
        body: args,
      }),
    }),
    registration: builder.mutation<void, IRegistrationForm>({
      query: (args) => ({
        url: '/registration',
        method: 'POST',
        body: args,
      }),
    }),
    setPassword: builder.mutation<void, ISetPasswordArgs>({
      query: (args) => ({
        url: '/setpass',
        method: 'POST',
        body: args,
      }),
    }),
    verifyKey: builder.query<void, { type: string | null; id: string | null }>({
      query: (arg) => ({
        url: `/verify?key=${arg.id}&type=${arg.type}`,
        method: 'GET',
      }),
    }),
    createClient: builder.mutation<void, ICreateSandboxUserForm>({
      query: (arg) => ({
        url: '/clients',
        method: 'POST',
        body: arg,
      }),
    }),
    joinClient: builder.mutation<void, { siteClientId: string | null }>({
      query: (arg) => ({
        url: '/join_client',
        method: 'POST',
        body: arg,
      }),
    }),
    checkClientDoc: builder.mutation<boolean, { id: string | null }>({
      query: (arg) => ({
        url: `/clients/has_docs/${arg.id}`,
        method: 'GET',
      }),
    }),
    sendDocuments: builder.mutation<void, FormData>({
      query: (arg) => ({
        url: '/send_documents',
        method: 'POST',
        body: arg,
      }),
    }),
    getOrganizations: builder.mutation<
      IISearchOrganizationsResponse,
      ISearchOrganizations
    >({
      query: (arg) => ({
        url: '/organizations/list',
        method: 'POST',
        body: arg,
      }),
    }),
    resetPass: builder.mutation<void, IEmailForResetPassForm>({
      query: (arg) => ({
        url: '/reset-pass',
        method: 'POST',
        body: arg,
      }),
    }),
  }),
});

export const {
  useLogoutMutation,
  useHearbeatQuery,
  useConfigQuery,
  useUdataQuery,
  useSetFactsMutation,
  useRegistrationMutation,
  useSetPasswordMutation,
  useVerifyKeyQuery,
  useGetOrganizationsMutation,
  useCreateClientMutation,
  useJoinClientMutation,
  useCheckClientDocMutation,
  useSendDocumentsMutation,
  useResetPassMutation,
} = authApi;
