import { docsApi } from 'entities/Documentation/model/docsApi';
import { ICodeBlock } from 'entities/Documentation/model/types';

const codeBlockApi = docsApi.injectEndpoints({
  endpoints: (builder) => ({
    editCodeBlock: builder.mutation<
      void,
      { id: number; value: string; blockId: number }
    >({
      query: (arg) => ({
        url: `blocks/code-clock/edit/${arg.id}`,
        method: 'PATCH',
        body: { value: arg.value },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Blocks', id: arg.blockId },
      ],
    }),
    createCodeBlock: builder.mutation<
      ICodeBlock,
      { headId: number; currentBlockNumber: number }
    >({
      query: (arg) => ({
        url: '/blocks/code-clock',
        method: 'POST',
        body: {
          headId: arg.headId,
          currentBlockNumber: arg.currentBlockNumber,
        },
      }),
      invalidatesTags: ['Blocks'],
    }),
    deleteCodeBlock: builder.mutation<
      void,
      { codeBlockId: number; blockId: number }
    >({
      query: (arg) => ({
        url: `/blocks/code-clock/${arg.codeBlockId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Blocks', id: arg.blockId },
      ],
    }),
  }),
});

export const {
  useEditCodeBlockMutation,
  useCreateCodeBlockMutation,
  useDeleteCodeBlockMutation,
} = codeBlockApi;
