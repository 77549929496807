import React, { FC } from 'react';

import './Footer.style.less';

import { Link } from 'react-router-dom';
import Divider from 'shared/assets/svg/divider.svg';

import getTelegramLink from 'shared/lib/getTgLink';
import trackEvent from 'shared/lib/top100TrackEvent';
import Top100Event from 'shared/types/top100';

const Footer: FC = () => {
  return (
    <footer className="footer">
      {/* <div className="logo-container"> */}
      <div className="logo-container">
        <Link to="/" className="campaign-link">
          <img
            src="/assets/svg/footer-ord.svg"
            className="ord-footer"
            title="ОРД от SberAds"
            alt="ОРД от SberAds"
          />
        </Link>
        <Divider className="divider" />
        <Link
          to="https://ads.sber.ru/"
          target="_blank"
          className="campaign-link"
        >
          <img
            className="sber-footer"
            src="/assets/svg/footer-sberads.svg"
            title="Рекламная платформа на данных экосистемы Сбера"
            alt="SberAds - платформа для размещения рекламы в интернете"
          />
        </Link>
      </div>
      {/* </div> */}
      <div className="footer-info">
        <div className="block">
          <p className="description">Адрес:</p>
          <p className="text">
            © 2024 ООО «Лаборатория Разработки» <br /> 117105, г. Москва,
            Варшавское шоссе, д.9, стр.1
          </p>
        </div>
        <div className="block">
          <p className="description">Техническая поддержка:</p>
          <p className="text">
            Пн-Чт: 10:00 -18:00 МСК <br />
            Пт: 10:00 -17:00 МСК
          </p>
        </div>
        <div className="block">
          <p className="description">По любым вопросам:</p>
          <p className="text">
            <a href="mailto:info@ord-lab.ru" className="footer-mail-to">
              info@ord-lab.ru
            </a>
          </p>
        </div>
      </div>
      <span className="footer-horizontal-divider" />
      <div className="footer-link">
        <Link
          to="/knowledge-base"
          onClick={() => trackEvent(Top100Event.FOOTER_LINK_KBASE)}
        >
          База знаний
        </Link>
        <Link
          to="/pricing"
          onClick={() => trackEvent(Top100Event.FOOTER_LINK_PRICE)}
        >
          Тарифы
        </Link>
        <Link
          to="https://t.me/ordsberads"
          target="_blank"
          onClick={() => trackEvent(Top100Event.FOOTER_LINK_TG_CHANNEL)}
        >
          Telegram
        </Link>
        <Link
          to={getTelegramLink()}
          target="_blank"
          onClick={() => trackEvent(Top100Event.FOOTER_LINK_TG_BOT)}
        >
          Telegram ORD Bot
        </Link>
        <Link
          to="/legal/privacy"
          onClick={() => trackEvent(Top100Event.FOOTER_LINK_TERMS)}
        >
          Политика конфиденциальности
        </Link>
        <Link
          to="/legal/terms-of-use"
          onClick={() => trackEvent(Top100Event.FOOTER_LINK_KBASE)}
        >
          Условия использования
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
